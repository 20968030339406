import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledWrapper = styled.section`
  div#jobs {
    .flex-container {
      .flex-column {
        li {
          margin: 0 0 2em 0;

          span.dates,
          span.roles {
            font-size: 0.8em;
            position: absolute;
            top: 24px;
          }

          span.dates {
            font-weight: bold;
            left: 35px;
          }

          span.roles {
            left: 130px;
          }
        }
      }
    }
  }
`;

const JobsBlock = props => {
  const { jobs, title, className } = props;
  return (
    <StyledWrapper className={className}>
      <div id="jobs">
        <p>{title}</p>
        <div className="flex-container">
          <div className="flex-column">
            <ul>
              {jobs.map((job, i) => (
                <li key={i}>
                  <span
                    role="img"
                    aria-label={job.type.key}
                    title={job.type.title}
                  >
                    {job.type.emoji}
                  </span>
                  &nbsp;{job.employer}
                  <span className="dates">{`${job.date.from} - ${job.date.to}`}</span>
                  <span className="roles">{job.roles}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

JobsBlock.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        emoji: PropTypes.string,
      }),
      date: PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      }),
      roles: PropTypes.string,
      employer: PropTypes.string,
    })
  ),
};

JobsBlock.defaultProps = {
  jobs: [],
};

export default JobsBlock;
