import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.section`
  span {
    &.s-on {
    }

    &.s-off {
      opacity: 0.1;
    }
  }
`;

const Stars = props => {
  let { starsOn, starsOff, icon } = props;
  starsOn = starsOn || 0;
  starsOff = starsOff || 0;
  icon = icon || '⭐';

  return (
    <StyledWrapper>
      <span className="s-off" role="img" aria-label="star">
        {icon.repeat(starsOff)}
      </span>
      <span className="s-on" role="img" aria-label="star">
        {icon.repeat(starsOn)}
      </span>
    </StyledWrapper>
  );
};

export default Stars;
