import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Stars from './Stars';

const StyledWrapper = styled.section``;

const SkillsBlockColumn = props => {
  const { skills, maxPoints, icon } = props;
  return (
    <StyledWrapper>
      <div className="flex-column">
        <ul>
          {skills.map((skill, i) => (
            <li key={i}>
              {skill.name}
              <Stars
                icon={icon}
                starsOn={skill.points}
                starsOff={maxPoints - skill.points}
              />
            </li>
          ))}
        </ul>
      </div>
    </StyledWrapper>
  );
};

SkillsBlockColumn.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.name,
      points: PropTypes.number,
    })
  ),
  maxPoints: PropTypes.number,
  icon: PropTypes.string,
};

SkillsBlockColumn.defaultProps = {
  skills: [],
  maxPoints: 5,
  icon: '☕',
};

export default SkillsBlockColumn;
