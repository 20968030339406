const Books = [
  {
    title: "Mówi się. Porady językowe profesora Bralczyka",
    authors: [ "Jerzy Bralczyk" ],
    myRating: 0,
    avgRating: 3.17,
    dateRead: "",
    status: "reading",
    picUrl: '../covers/mowi_sie.jpg',
    url: 'https://www.goodreads.com/book/show/16070009-m-wi-si-porady-j-zykowe-profesora-bralczyka',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Agile Retrospectives: Making Good Teams Great",
    authors: [ "Esther Derby", "Diana Larsen"],
    myRating: 0,
    avgRating: 3.93,
    dateRead: "",
    status: "reading",
    picUrl: '../covers/agile_retro.jpg',
    url: 'https://www.goodreads.com/book/show/721338.Agile_Retrospectives',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Na Zachodzie bez zmian",
    authors: [ "Erich Maria Remarque" ],
    myRating: 5,
    avgRating: 3.99,
    dateRead: "2020/11/01",
    status: "read",
    picUrl: '../covers/na_zachodzie.jpg',
    url: 'https://www.goodreads.com/book/show/54968982-na-zachodzie-bez-zmian',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Rzeczy, których nie wyrzuciłem",
    authors: [ "Marcin Wicha" ],
    myRating: 4,
    avgRating: 4.13,
    dateRead: "2020/12/01",
    status: "read",
     picUrl: '../covers/rzeczy_ktorych.jpg',
    url: 'https://www.goodreads.com/book/show/34665522-rzeczy-kt-rych-nie-wyrzuci-em',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "„Pan raczy żartować, panie Feynman!” Przypadki ciekawego człowieka",
    authors: [ "Richard P. Feynman" ],
    myRating: 4,
    avgRating: 4.26,
    dateRead: "2021/01/01",
    status: "read",
    picUrl: '../covers/pan_raczy_zartowac.jpg',
    url: 'https://www.goodreads.com/book/show/42443704-pan-raczy-artowa-panie-feynman-przypadki-ciekawego-cz-owieka',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "27 śmierci Toby'ego Obeda",
    authors: [ "Joanna Gierak-Onoszko" ],
    myRating: 5,
    avgRating: 4.64,
    dateRead: "2020/12/31",
    status: "read",
    picUrl: '../covers/27_smierci.jpg',
    url: 'https://www.goodreads.com/book/show/44647518-27-mierci-toby-ego-obeda',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Scrum: The Art of Doing Twice the Work in Half the Time",
    authors: [ "Jeff Sutherland" ],
    myRating: 4,
    avgRating: 4.19,
    dateRead: "2020/10/11",
    status: "read",
    picUrl: '../covers/scrum_the_art.jpg',
    url: 'https://www.goodreads.com/book/show/19288230-scrum',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Siła nawyku",
    authors: [ "Charles Duhigg" ],
    myRating: 5,
    avgRating: 4.11,
    dateRead: "2020/09/29",
    status: "read",
    picUrl: '../covers/sila_nawyku.jpg',
    url: 'https://www.goodreads.com/book/show/17699395-si-a-nawyku',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Patterns of Enterprise Application Architecture",
    authors: [ "Martin Fowler" ],
    myRating: 0,
    avgRating: 4.11,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/patterns_of_enterprise.jpg',
    url: 'https://www.goodreads.com/book/show/70156.Patterns_of_Enterprise_Application_Architecture',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Head First Design Patterns",
    authors: [ "Eric Freeman", "Elisabeth Robson" ],
    myRating: 0,
    avgRating: 4.28,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/head_first_design_patterns.jpg',
    url: 'https://www.goodreads.com/book/show/53483756-head-first-design-patterns',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Wehikuł czasu",
    authors: [ "H.G. Wells" ],
    myRating: 0,
    avgRating: 3.89,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/wehikul_czasu.jpg',
    url: 'https://www.goodreads.com/book/show/11795856-wehiku-czasu',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Salamandra",
    authors: [ "Stefan Grabiński" ],
    myRating: 3,
    avgRating: 3.25,
    dateRead: "2020/10/30",
    status: "read",
    picUrl: '../covers/salamandra.jpg',
    url: 'https://www.goodreads.com/book/show/20873317-salamandra',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Truciciele z KGB. Likwidacja wrogów Kremla od Lenina do Litwinienki",
    authors: [ "Borys Wołodarski" ],
    myRating: 0,
    avgRating: 3.67,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/truciciele_z_kgb.jpg',
    url: 'https://www.goodreads.com/book/show/36137668-truciciele-z-kgb-likwidacja-wrog-w-kremla-od-lenina-do-litwinienki',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "JavaScript - Mocne Strony",
    authors: [ "Douglas Crockford" ],
    myRating: 0,
    avgRating: 4.23,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/javascript_mocne_strony.jpg',
    url: 'https://www.goodreads.com/book/show/20796842-javascript---mocne-strony',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Życie i czasy Sknerusa McKwacza (Życie i czasy Sknerusa McKwacza, #1-2)",
    authors: [ "Don Rosa" ],
    myRating: 5,
    avgRating: 4.67,
    dateRead: "2019/01/01",
    status: "read",
    picUrl: '../covers/zycie_i_czasy.jpg',
    url: 'https://www.goodreads.com/book/show/37162296-ycie-i-czasy-sknerusa-mckwacza',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Fakt, nie mit",
    authors: [ "Aleksandra Stanisławska", "Piotr Stanisławski" ],
    myRating: 0,
    avgRating: 3.57,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/fakt_nie_mit.jpg',
    url: 'https://www.goodreads.com/book/show/50795350-fakt-nie-mit',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "The Radium Girls: The Dark Story of America's Shining Women",
    authors: [ "Kate  Moore" ],
    myRating: 0,
    avgRating: 4.13,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/the_radium_girls.jpg',
    url: 'https://www.goodreads.com/book/show/43751067-the-radium-girls-mroczna-historia-promiennych-kobiet-ameryki',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Polowanie na Czerwony Październik (Jack Ryan, #3)",
    authors: [ "Tom Clancy" ],
    myRating: 0,
    avgRating: 4.08,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/polowanie_na_czerwony.jpg',
    url: 'https://www.goodreads.com/book/show/6289715-polowanie-na-czerwony-pa-dziernik',
    sideColor: '#171811',
    brightColor: false,
  },
  {
    title: "Odwaga bycia nielubianym. Japoński fenomen, który pokazuje, jak być wolnym i odmienić własne życie",
    authors: [ "Ichiro Kishimi" ],
    myRating: 0,
    avgRating: 4.08,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/odwaga_bycia.jpg',
    url: 'https://www.goodreads.com/book/show/44000884-odwaga-bycia-nielubianym-japo-ski-fenomen-kt-ry-pokazuje-jak-by-woln',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Mit przedsiębiorczości: Dlaczego większość małych firm upada i jak temu zaradzić",
    authors: [ "Michael E. Gerber" ],
    myRating: 0,
    avgRating: 4.03,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/mit_przedsiebiorczosci.jpg',
    url: 'https://www.goodreads.com/book/show/33410428-mit-przedsi-biorczo-ci',
    sideColor: '#171811',
    brightColor: false,
  },
  {
    title: "21 lekcji na XXI wiek",
    authors: [ "Yuval Noah Harari" ],
    myRating: 0,
    avgRating: 4.16,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/21_lekcji.jpg',
    url: 'https://www.goodreads.com/book/show/41215605-21-lekcji-na-xxi-wiek',
    sideColor: '#FFFAD9',
    brightColor: true,
  },
  {
    title: "Ludzie. Krótka historia o tym, jak spieprzyliśmy wszystko",
    authors: [ "Tom Phillips" ],
    myRating: 0,
    avgRating: 4.04,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/ludzie_krotka_historia.jpg',
    url: 'https://www.goodreads.com/book/show/45019906-ludzie-kr-tka-historia-o-tym-jak-spieprzyli-my-wszystko',
    sideColor: '#FFF7E2',
    brightColor: true,
  },
  {
    title: "Jak zdobyć przyjaciół i zjednać sobie ludzi",
    authors: [ "Dale Carnegie" ],
    myRating: 5,
    avgRating: 4.21,
    dateRead: "2020/09/05",
    status: "read",
    picUrl: '../covers/jak_zdobic_przyjaciol.jpg',
    url: 'https://www.goodreads.com/book/show/9253814-jak-zdoby-przyjaci-i-zjedna-sobie-ludzi',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Domain-Driven Design: Tackling Complexity in the Heart of Software",
    authors: [ "Eric Evans" ],
    myRating: 0,
    avgRating: 4.16,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/ddd.jpg',
    url: 'https://www.goodreads.com/book/show/179133.Domain_Driven_Design',
    sideColor: '#01B3D1',
    brightColor: true,
  },
  {
    title: "Mistrz czystego kodu. Kodeks postępowania profesjonalnych programistów",
    authors: [ "Robert C. Martin" ],
    myRating: 0,
    avgRating: 4.28,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/mistrz_czystego_kodu.jpg',
    url: 'https://www.goodreads.com/book/show/19357402-mistrz-czystego-kodu-kodeks-post-powania-profesjonalnych-programist-w',
    sideColor: '#000',
    brightColor: false,
  },
  {
    title: "Test-Driven Development: By Example",
    authors: [ "Kent Beck" ],
    myRating: 0,
    avgRating: 4.08,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/tdd.jpg',
    url: 'https://www.goodreads.com/book/show/387190.Test_Driven_Development',
    sideColor: '#000',
    brightColor: false,
  },
  {
    title: "Kanban and Scrum - Making the Most of Both",
    authors: [ "Henrik Kniberg", "Mattias Skarin" ],
    myRating: 4,
    avgRating: 3.96,
    dateRead: "2020/02/01",
    status: "read",
    picUrl: '../covers/kanban_and_scrum.jpg',
    url: 'https://www.goodreads.com/book/show/8167480-kanban-and-scrum---making-the-most-of-both',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Firma",
    authors: [ "John Grisham" ],
    myRating: 4,
    avgRating: 4.03,
    dateRead: "2014/05/05",
    status: "read",
    picUrl: '../covers/firma.jpg',
    url: 'https://www.goodreads.com/book/show/56856033-firma',
    sideColor: '#2D2E80',
    brightColor: false,
  },
  {
    title: "Diamenty są wieczne",
    authors: [ "Ian Fleming" ],
    myRating: 3,
    avgRating: 3.66,
    dateRead: "2018/10/10",
    status: "read",
    picUrl: '../covers/diamenty_sa_wieczne.jpg',
    url: 'https://www.goodreads.com/book/show/9759960-diamenty-s-wieczne',
    sideColor: '#000',
    brightColor: false,
  },
  {
    title: "Bractwo",
    authors: [ "John Grisham" ],
    myRating: 3,
    avgRating: 3.74,
    dateRead: "2016/01/01",
    status: "read",
    picUrl: '../covers/bractwo.jpg',
    url: 'https://www.goodreads.com/book/show/13319439-bractwo',
    sideColor: '#9B9CA5',
    brightColor: true,
  },
  {
    title: "Tylko dla orłów",
    authors: [ "Alistair MacLean" ],
    myRating: 5,
    avgRating: 4.14,
    dateRead: "2014/02/02",
    status: "read",
    picUrl: '../covers/tylko_dla_orlow.jpg',
    url: 'https://www.goodreads.com/book/show/53283906-tylko-dla-or-w',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Zielona mila",
    authors: [ "Stephen King" ],
    myRating: 5,
    avgRating: 4.45,
    dateRead: "2009/03/05",
    status: "read",
    picUrl: '../covers/zielona_mila.jpg',
    url: 'https://www.goodreads.com/book/show/6534516-zielona-mila',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Autostopem przez galaktykę (Autostopem przez Galaktykę, #1)",
    authors: [ "Douglas Adams" ],
    myRating: 5,
    avgRating: 4.22,
    dateRead: "2012/05/05",
    status: "read",
    picUrl: '../covers/autostopem_przez_galaktyke.jpg',
    url: 'https://www.goodreads.com/book/show/6516071-autostopem-przez-galaktyk',
    sideColor: '#A61F5D',
    brightColor: false,
  },
  {
    title: "Pułapki myślenia. O myśleniu szybkim i wolnym",
    authors: [ "Daniel Kahneman" ],
    myRating: 0,
    avgRating: 4.16,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/pulapki_myslenia.jpg',
    url: 'https://www.goodreads.com/book/show/17161472-pu-apki-my-lenia-o-my-leniu-szybkim-i-wolnym',
    sideColor: '#1F3239',
    brightColor: false,
  },
  {
    title: "Czysta architektura. Struktura i design oprogramowania. Przewodnik dla profesjonalistów",
    authors: [ "Robert C. Martin" ],
    myRating: 0,
    avgRating: 4.24,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/czysta_architektura.jpg',
    url: 'https://www.goodreads.com/book/show/40232028-czysta-architektura-struktura-i-design-oprogramowania-przewodnik-dla-p',
    sideColor: '#000',
    brightColor: false,
  },
  {
    title: "Traktat o łuskaniu fasoli",
    authors: [ "Wiesław Myśliwski" ],
    myRating: 5,
    avgRating: 4.26,
    dateRead: "2020/09/01",
    status: "read",
    picUrl: '../covers/traktat_o_luskaniu_fasoli.jpg',
    url: 'https://www.goodreads.com/book/show/42967997-traktat-o-uskaniu-fasoli',
    sideColor: '#292C28',
    brightColor: false,
  },
  {
    title: "Animal Farm",
    authors: [ "George Orwell" ],
    myRating: 5,
    avgRating: 3.96,
    dateRead: "2020/08/01",
    status: "read",
    picUrl: '../covers/animal_farm.jpg',
    url: 'https://www.goodreads.com/book/show/170448.Animal_Farm',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Don't Just Roll the Dice - a usefully short guide to software pricing",
    authors: [ "Neil  Davidson" ],
    myRating: 4,
    avgRating: 3.67,
    dateRead: "2020/02/15",
    status: "read",
    picUrl: '../covers/dont_just_roll_the_dice.jpg',
    url: 'https://www.goodreads.com/book/show/7039888-don-t-just-roll-the-dice---a-usefully-short-guide-to-software-pricing',
    sideColor: '#9A898F',
    brightColor: true,
  },
  {
    title: "Toksyczni ludzie",
    authors: [ "Lillian Glass" ],
    myRating: 4,
    avgRating: 3.57,
    dateRead: "2020/01/30",
    status: "read",
    picUrl: '../covers/toksyczni_ludzie.jpg',
    url: 'https://www.goodreads.com/book/show/22461619-toksyczni-ludzie',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Ostre cięcie. Jak niszczono polską kolej",
    authors: [ "Karol Trammer" ],
    myRating: 0,
    avgRating: 4.45,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/ostre_ciecie.jpg',
    url: 'https://www.goodreads.com/book/show/46268226-ostre-ci-cie-jak-niszczono-polsk-kolej',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Nie ma",
    authors: [ "Mariusz Szczygieł" ],
    myRating: 0,
    avgRating: 4.05,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/nie_ma.jpg',
    url: 'https://www.goodreads.com/book/show/42414413-nie-ma',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Obywatel PL",
    authors: [ "Adam Bodnar", "Bartosz Bartosik" ],
    myRating: 0,
    avgRating: 4.24,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/obywatel_pl.jpg',
    url: 'https://www.goodreads.com/book/show/53299872-obywatel-pl',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Solaris",
    authors: [ "Stanisław Lem" ],
    myRating: 0,
    avgRating: 3.99,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/solaris.jpg',
    url: 'https://www.goodreads.com/book/show/13557684-solaris',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Bezpieczeństwo aplikacji webowych",
    authors: [ "Michał Bentkowski", "Artur Czyż", "Rafał Janicki", "Jarosław Kamiński", "Adrian Michalczyk", "Mateusz Niezabitowski", "Marcin Piosek", "Michał Sajdak", "Grzegorz Trawiński", "Bohdan Widła" ],
    myRating: 0,
    avgRating: 3.65,
    dateRead: "",
    status: "reading",
    picUrl: '../covers/baw.jpg',
    url: 'https://www.goodreads.com/book/show/48718398-bezpiecze-stwo-aplikacji-webowych',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Getting Things Done: The Art of Stress-Free Productivity",
    authors: [ "David Allen" ],
    myRating: 0,
    avgRating: 3.98,
    dateRead: "",
    status: "to-read",
    picUrl: '../covers/getting_things_done.jpg',
    url: 'https://www.goodreads.com/book/show/29199552-getting-things-done-czyli-sztuka-bezstresowej-efektywno-ci-wydanie-ii',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Mit charyzmy. Każdy może stać się mistrzem w wyzwalaniu osobistego magnetyzmu",
    authors: [ "Olivia Fox Cabane" ],
    myRating: 5,
    avgRating: 3.94,
    dateRead: "2020/01/15",
    status: "read",
    picUrl: '../covers/mit_charyzmy.jpg',
    url: 'https://www.goodreads.com/book/show/33981814-mit-charyzmy-ka-dy-mo-e-sta-si-mistrzem-w-wyzwalaniu-osobistego-magne',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Pragmatyczny programista Od czeladnika do mistrza",
    authors: [ "Thomas David", "Hunt Andrew" ],
    myRating: 0,
    avgRating: 3.78,
    dateRead: "",
    status: "reading",
    picUrl: '../covers/pragmatyczny_programista.jpg',
    url: 'https://www.goodreads.com/book/show/40913638-pragmatyczny-programista-od-czeladnika-do-mistrza',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Czysty kod. Podręcznik dobrego programisty",
    authors: [ "Robert C. Martin" ],
    myRating: 5,
    avgRating: 4.4,
    dateRead: "2020/02/01",
    status: "read",
    picUrl: '../covers/czysty_kod.jpg',
    url: 'https://www.goodreads.com/book/show/9755563-czysty-kod-podr-cznik-dobrego-programisty',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Sztuka podstępu - łamalem ludzi, nie hasła",
    authors: [ "William L. Simon", "Kevin D. Mitnick" ],
    myRating: 4,
    avgRating: 3.52,
    dateRead: "2020/05/05",
    status: "read",
    picUrl: '../covers/sztuka_podstepu.jpg',
    url: 'https://www.goodreads.com/book/show/40532044-sztuka-podstepu-lamalem-ludzi-nie-hasla-w2',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Kongres futurologiczny. Opowiadania Ijona Tichego",
    authors: [ "Stanisław Lem" ],
    myRating: 5,
    avgRating: 4.21,
    dateRead: "2020/07/07",
    status: "read",
    picUrl: '../covers/kongres_futurologiczny.jpg',
    url: 'https://www.goodreads.com/book/show/13557689-kongres-futurologiczny',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Finansowy ninja",
    authors: [ "Michał Szafrański" ],
    myRating: 4,
    avgRating: 4.38,
    dateRead: "2020/01/15",
    status: "read",
    picUrl: '../covers/finansowy_ninja.jpg',
    url: 'https://www.goodreads.com/book/show/29151508-finansowy-ninja',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Praca głęboka. Jak odnieść sukces w świecie, w którym ciągle coś nas rozprasza",
    authors: [ "Cal Newport" ],
    myRating: 4,
    avgRating: 4.19,
    dateRead: "2020/03/03",
    status: "read",
    picUrl: '../covers/praca_gleboka.jpg',
    url: 'https://www.goodreads.com/book/show/40805406-praca-g-boka-jak-odnie-sukces-w-wiecie-w-kt-rym-ci-gle-co-nas-roz',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Statystycznie rzecz biorąc, czyli ile trzeba zjeść czekolady, żeby dostać Nobla?",
    authors: [ "Janina Bąk" ],
    myRating: 3,
    avgRating: 3.65,
    dateRead: "2020/04/04",
    status: "read",
    picUrl: '../covers/statystycznie.jpg',
    url: 'https://www.goodreads.com/book/show/51477728-statystycznie-rzecz-bior-c-czyli-ile-trzeba-zje-czekolady-eby-dosta',
    sideColor: '#fff',
    brightColor: true,
  },
  {
    title: "Mieszkania do wynajęcia Moja droga do wolności finansowej",
    authors: [ "Sławek Muturi" ],
    myRating: 3,
    avgRating: 4.2,
    dateRead: "2020/01/01",
    status: "read",
    picUrl: '../covers/mieszkania_na_wynajem.jpg',
    url: 'https://www.goodreads.com/book/show/36099097-mieszkania-na-wynajem-moja-droga-do-wolno-ci-finansowej',
    sideColor: '#fff',
    brightColor: true,
  }
 ];

export default Books;
