import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledWrapper = styled.section`
  div#employer {
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      li {
        margin: 10px;

        img {
          height: 70px;
        }
      }
    }
  }
`;

const EmployersLogosBlock = props => {
  const { employers, title, className } = props;
  return (
    <StyledWrapper className={className}>
      <div id="employer">
        <p>{title}</p>
        <ul>
          {employers.map((employer, i) => (
            <li key={i}>
              <img src={employer.imgUrl} alt={employer.employer} />
            </li>
          ))}
        </ul>
      </div>
    </StyledWrapper>
  );
};

EmployersLogosBlock.propTypes = {
  employers: PropTypes.arrayOf(
    PropTypes.shape({
      employer: PropTypes.string.isRequired,
      imgUrl: PropTypes.string.isRequired,
    })
  ),
};

EmployersLogosBlock.defaultProps = {
  employers: [],
};

export default EmployersLogosBlock;
