import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SkillsBlockColumn from './SkillsBlockColumn';

const StyledWrapper = styled.section`
  .flex-container > * {
    flex: 1 0 250px;
  }
`;

const SkillsBlock = props => {
  const { skills, title, maxPoints, className, icon } = props;
  const skillsCol1 = skills.slice(0, skills.length / 2);
  const skillsCol2 = skills.slice(skills.length / 2, skills.length);
  return (
    <StyledWrapper className={className}>
      <p>{title}</p>
      <div className="flex-container">
        <SkillsBlockColumn
          skills={skillsCol1}
          maxPoints={maxPoints}
          icon={icon}
        />
        <SkillsBlockColumn
          skills={skillsCol2}
          maxPoints={maxPoints}
          icon={icon}
        />
      </div>
    </StyledWrapper>
  );
};

SkillsBlock.propTypes = {
  maxPoints: PropTypes.number,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.name,
      points: PropTypes.number,
    })
  ),
  icon: PropTypes.string,
};

SkillsBlock.defaultProps = {
  maxPoints: 5,
  skills: [],
  icon: '*',
};

export default SkillsBlock;
