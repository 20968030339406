import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.section`
  .bio--photo {
    width: 200px;
    margin: 0 15px 15px 0;
    float: left;
  }

  em {
    font-style: normal;
    background-color: #00DBDE;
    background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
`;

const Bio = (props) => {
  return (
    <StyledWrapper>
      <img
        className="bio--photo"
        src="../photos/min.jpg"
        alt="Cześć, to ja :)"
      />
      <p>
        <b>Nazywam się Karol Laskowski</b>
      </p>
      <p>
        Na co dzień pracuję jako <em>Senior .NET Web Developer</em> w&nbsp;Białymstoku.
        Programuję głównie w <em>ASP.NET MVC C#</em>. Jestem
        entuzjastą rozwiązań frontendowych, szczególnie <em>fundamentów języka
        JavaScript</em>. Tworzę i&nbsp;zarządzam stronami opartymi o CMSy
        Wordpress i&nbsp;Drupal (PHP).
      </p>
      <p>
        Od ponad roku ćwiczę także swoje umiejętności jako <em>Scrum Master</em>.
      </p>
      <p>
        Angażuję się w organizację rekrutacji i promocji podczas targów
        pracy i staży, spotkań integracyjnych i innych wydarzeń
        współorganizowanych z HR. Pracowałem nad przygotowaniem <em>cyklu
        wewnętrznych tech-meetingów</em> i dążę do tego, by łączyć możliwość
        doskonalenia techniki wystąpień publicznych z okazją do dzielenia się
        wiedzą między programistami o różnym stażu pracy i&nbsp;poziomie doświadczenia.
      </p>
      <p>
        Poza programowaniem interesuję się projektami Do It Yourself
        obejmującymi elektronikę, obróbkę drewna i metalu, modelarstwo.
      </p>
      <p>
        Wolny czas przeznaczam także na książki rozwojowe jak i fabularne,
        uprawianie sportów drużynowych, jazdę na rowerze oraz planszówki i gry komputerowe.
      </p>
    </StyledWrapper>
  );
}

export default Bio;