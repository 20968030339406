import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomCssVars = {
  direction: -1,
  bookSideColor: '#aaa',
  bookPagesColor: '#ddd',
  liftTransitionTime: '0.3s',
};

const StyledWrapper = styled.section`
  #iso-books-3d {
    border: 1px solid red;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;

    #iso-books-3d--container {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
      gap: 12px;
      align-items: flex-start;
      justify-content: space-evenly;
      width: 800px;
      transform: rotateX(60deg) rotateY(0deg)
        rotateZ(calc(${CustomCssVars.direction} * 45deg));
      transform-style: preserve-3d;

      .book-3d--wrapper {
        width: 160px;
        height: 200px;
        background-color: rgba(0, 0, 0, 0.25);
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.25);
        transition: ${CustomCssVars.liftTransitionTime};

        &:hover {
          transition: ${CustomCssVars.liftTransitionTime};
          box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.25);
        }

        .book-3d {
          position: relative;
          width: 160px;
          height: 200px;
          transform-style: preserve-3d;
          transform: translate3D(0px, 0px, 20px);
          transition: transform ${CustomCssVars.liftTransitionTime};

          &::before {
            content: '';
            position: absolute;
            width: 200px;
            height: 20px;
            font-size: 10px;
            font-weight: bolder;
            text-align: center;
            background-color: attr(data-side-color) black;
            transform-origin: left top;
            transform: rotateZ(90deg) rotateX(-90deg);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 160px;
            height: 20px;
            background-color: ${CustomCssVars.bookPagesColor};
            transform-origin: left top;
            transform: translate(0, 200px) rotateX(-90deg);
          }

          img {
            width: 160px;
            height: 200px;
          }
        }

        &:hover .book-3d {
          transform: translate3D(0px, 0px, 50px);
          transition: all ${CustomCssVars.liftTransitionTime} ease-out;

          &:before,
          &:after {
          }
        }
      }
    }
  }
`;


class BooksList3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      books: props.books,
    }
    this.setColorToBookSide = this.setColorToBookSide.bind(this);
    
  }
  
  setColorToBookSide(bookElement) {
    const color = bookElement.getAttribute('data-side-color')
    window.getComputedStyle(bookElement, ':before').setPropertyValue('color', color);
  }

  render() {
    const { books } = this.state;
    return (
      <StyledWrapper>
        <div id="iso-books-3d">
          <div id="iso-books-3d--container">
            {books.map(b => (
              <div key={b.key ? b.key : `${b.authors[0]} - ${b.title}`} className="book-3d--wrapper">
                <div
                  className="book-3d"
                  data-side-color={b.sideColor}
                  data-side-text={`${b.authors[0]} - ${b.title}`}
                  >
                  <img
                    src={b.picUrl}
                    alt={`${b.authors[0]} - ${b.title}`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </StyledWrapper>
    );
  }
};

BooksList3D.propTypes = {
  books: PropTypes.arrayOf(PropTypes.shape()),
};

BooksList3D.defaultProps = {
  books: [],
};

export default BooksList3D;
