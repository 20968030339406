import React from 'react';
import PropTypes from 'prop-types';

class BooksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      books: props.books,
    }    
  }

  render() {
    const { books } = this.state;
    return (
      <div id="iso-books">
        <p>{this.props.title}</p>
        <ul>
          {
            books.map(b => (
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={b.url}
                >
                  {b.key ? b.key : `${b.authors[0]} - ${b.title}`}
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
};

BooksList.propTypes = {
  books: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string.isRequired,
};

BooksList.defaultProps = {
  books: [],
  title: '',
};

export default BooksList;
