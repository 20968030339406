const Skills = [
  { name: 'C#, ASP.NET MVC', points: 4 },
  { name: 'JavaScript', points: 4 },
  { name: 'CSS, SCSS', points: 4 },
  { name: 'Scrum', points: 3 },
  { name: 'MS SQL, MySQL', points: 3 },
  { name: 'Wordpress, Drupal', points: 3 },
  { name: 'React', points: 2 },
  { name: 'Angular, Gatsby, GraphQL', points: 1 },
];

export default Skills;
