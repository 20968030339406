const Jobs = [
  {
    type: {
      key: 'commercial-work',
      title: 'Projekty komercyjne',
      emoji: '💻',
    },
    employer: 'Rydoo',
    date: { from: '2018', to: '2021' },
    roles: 'ASP.NET MVC Developer & Frontend / Scrum Master',
    imgUrl: '../companies/rydoo.png',
  },
  {
    type: {
      key: 'commercial-work',
      title: 'Projekty komercyjne',
      emoji: '💻',
    },
    employer: 'iAlbatros',
    date: { from: '2016', to: '2018' },
    roles: 'ASP.NET MVC Developer & Frontend',
    imgUrl: '../companies/ialb.png',
  },
  {
    type: {
      key: 'voluntary-work',
      title: 'Wolontariat',
      emoji: '🙋‍♂️',
    },
    employer: 'Doktoranckie Forum Uczelni Medycznych',
    date: { from: '2019', to: '...' },
    roles: 'Webmaster - Wordpress',
  },
  {
    type: {
      key: 'voluntary-work',
      title: 'Wolontariat',
      emoji: '🙋‍♂️',
    },
    employer: 'Samorząd Doktorantów UMB',
    date: { from: '2019', to: '...' },
    roles: 'Webmaster - Wordpress',
  },
  {
    type: {
      key: 'commercial-work',
      title: 'Projekty komercyjne',
      emoji: '💻',
    },
    employer: 'MTZ Belarus',
    date: { from: '2017', to: '2018' },
    roles: 'Webmaster - Wordpress / Serwis techniczny',
  },
  {
    type: {
      key: 'commercial-work',
      title: 'Projekty komercyjne',
      emoji: '💻',
    },
    employer: 'SMT Software S.C.',
    date: { from: '2013', to: '2016' },
    roles: 'ASP.NET Developer',
    imgUrl: '../companies/smt.png',
  },
  {
    type: {
      key: 'voluntary-work',
      title: 'Wolontariat',
      emoji: '🙋‍♂️',
    },
    employer: 'Erasmus Student Network Polska',
    date: { from: '2014', to: '2016' },
    roles: 'Przewodniczący Komitetu IT - Webmaster - Drupal',
    imgUrl: '../companies/esn.png',
  },
  {
    type: {
      key: 'commercial-work',
      title: 'Projekty komercyjne',
      emoji: '💻',
    },
    employer: 'AuroraSoft S.C.',
    date: { from: '2009', to: '2012' },
    roles: 'ASP.NET Developer',
    imgUrl: '../companies/aurora.png',
  },
  {
    type: {
      key: 'education',
      title: 'Edukacja',
      emoji: '🏫',
    },
    employer: 'Technical University in Leiria',
    date: { from: '2008', to: '2009' },
    roles: 'wymiana studencka Erasmus',
  },
  {
    type: {
      key: 'education',
      title: 'Edukacja',
      emoji: '🏫',
    },
    employer: 'Politechnika Białostocka',
    date: { from: '2006', to: '2010' },
    roles: 'informatyka',
  },
  {
    type: {
      key: 'education',
      title: 'Edukacja',
      emoji: '🏫',
    },
    employer: 'Technikum Elektryczne',
    date: { from: '2002', to: '2006' },
    roles: 'technik elektronik',
  },
];

export default Jobs;
