import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Bio from '../components/Bio';
import Head from '../components/Head';
import BooksList from '../components/BooksList';
import BooksList3D from '../components/BooksList3D';
import JobsBlock from '../components/JobsBlock';
import EmployersLogosBlock from '../components/EmployersLogosBlock';
import SkillsBlock from '../components/SkillsBlock';

import Jobs from '../data/jobs';
import Skills from '../data/skills';
import Books from '../data/books';

const Settings = {
  showBooks3D: false,
  showBooksList: true,
  showEmployers: true,
  showJobs: false,
  showSkills: true,
};

const StyledWrapper = styled.section`
  text-align: justify;
  font-family: ${({ theme }) =>
    `"${theme.fonts.code}", ${theme.fonts.fallbackMono}`};

  > a {
    display: inline-block;
  }

  > * {
    &:not(:first-child) {
      margin-top: 30px;

      p {
        font-weight: bold;
      }
    }

    & .flex-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      .flex-column {
        margin: 0 10px;
        align-self: stretch;
        flex: 1 0 40%;
        text-align: left;

        ul {
          padding-left: 10px;
          margin: 0;

          li {
            position: relative;
            border-bottom: 1px solid ${({ theme }) => theme.colors.text.link};

            section {
              float: right;
            }

            &:hover {
              border-bottom: 1px solid
                ${({ theme }) => theme.colors.text.linkHovered};
            }
          }
        }
      }
    }

    &.hidden {
      display: none;
    }

    &#see-more-btn {
      display: inline-block;
      margin-top: 30px;
      cursor: pointer;
      font-family: 'IBM Plex Mono';
      background-color: transparent;
      border: 0;
      font-size: 1em;

      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.text.linkHovered};
      }
    }
  }
`;

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
    this.handleShowMoreKeyPress = this.handleShowMoreKeyPress.bind(this);
    this.toggleShowMore = this.toggleShowMore.bind(this);
    //this.fetchBooksFromOpenLibrary = this.fetchBooksFromOpenLibrary.bind(this);
    this.fetchBooksFromFile = this.fetchBooksFromFile.bind(this);
    this.sortByDateReadDesc = this.sortByDateReadDesc.bind(this);
    this.getEmployersFromJobs = this.getEmployersFromJobs.bind(this);

    const newJobs = Jobs.sort(this.sortByDateReadDesc);
    const newEmployers = this.getEmployersFromJobs(newJobs);
    this.state = {
      showMoreToggleOn: false,
      jobs: newJobs,
      employers: newEmployers,
      books: Books,
      skills: Skills,
    };
  }

  getEmployersFromJobs(jobs) {
    return jobs.filter(job => job.imgUrl);
  }

  fetchBooksFromFile() {
    this.setState(state => ({
      showMoreToggleOn: this.state.showMoreToggleOn,
      books: Books,
      jobs: this.state.jobs,
      employers: this.state.employers,
      skills: this.state.skills,
    }));
  }

  fetchBooksFromOpenLibrary(fetchUrl) {
    fetch(fetchUrl)
      .then(response => response.json())
      .then(data => {
        const booksList = data.entries.map(item => {
          return {
            key: item.url,
            title: item.title,
            authors: item.authors.join(', '),
            status: item.status,
            url: item.url,
            picUrl: item.picture?.url,
            alt: `${item.authors[0]} - ${item.title}`,
            sideColor: '#fff',
          };
        });
        this.setState(state => ({
          showMoreToggleOn: this.state.showMoreToggleOn,
          books: booksList,
          jobs: this.state.jobs,
          employers: this.state.employers,
          skills: this.state.skills,
        }));
      })
      .catch(error => console.error(error));
  }

  handleShowMoreClick() {
    this.toggleShowMore();
  }

  handleShowMoreKeyPress() {
    this.toggleShowMore();
  }

  toggleShowMore() {
    this.setState(state => ({
      showMoreToggleOn: !this.state.showMoreToggleOn,
      books: this.state.books,
      jobs: this.state.jobs,
      employers: this.state.employers,
      skills: this.state.skills,
    }));
  }

  componentDidMount() {
    // this.fetchBooksFromOpenLibrary(
    //   'http://openlibrary.org/people/karollaskowski/lists/OL193738L/seeds.json'
    // );
    this.fetchBooksFromFile();
  }

  sortByDateReadDesc(a, b) {
    return a.dateRead > b.dateRead ? -1 : a.dateRead < b.dateRead ? 1 : 0;
  }

  render() {
    return (
      <Layout>
        <Head title="Contact" />
        <StyledWrapper>
          <section id="bio">
            <Bio />
          </section>
          <section id="contact-links" className="external-links">
            <a href="mailto:karol@laskowski.dev">karol@laskowski.dev</a>
            <a
              href="https://github.com/KarolLaskowski/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
            <a
              href="https://codepen.io/karollaskowski/pens/public"
              target="_blank"
              rel="noopener noreferrer"
            >
              CodePen
            </a>
            <a href="https://www.linkedin.com/in/karol-laskowski/">LinkedIn</a>
          </section>
          <button
            id="see-more-btn"
            onClick={this.handleShowMoreClick}
            onKeyPress={this.handleShowMoreKeyPress}
            role="switch"
            aria-checked="false"
          >
            {this.state.showMoreToggleOn ? 'Pokaż mniej...' : 'Pokaż więcej...'}
          </button>

          {Settings.showJobs && (
            <JobsBlock
              jobs={this.state.jobs}
              title="Moje doświadczenie"
              className={this.state.showMoreToggleOn ? '' : 'hidden'}
            />
          )}

          {Settings.showEmployers && (
            <EmployersLogosBlock
              employers={this.state.employers}
              title="Współpracowałem z"
              className={this.state.showMoreToggleOn ? '' : 'hidden'}
            />
          )}

          {Settings.showSkills && (
            <SkillsBlock
              skills={this.state.skills}
              title="Moje umiejętności"
              icon={'🍞'}
              maxPoints={5}
              className={this.state.showMoreToggleOn ? '' : 'hidden'}
            />
          )}

          {Settings.showBooksList && (
            <section
              id="books"
              className={this.state.showMoreToggleOn ? '' : 'hidden'}
            >
              <div className="flex-container">
                <div className="flex-column">
                  <BooksList
                    books={this.state.books
                      .filter(b => b.status === 'read')
                      .sort(this.sortByDateReadDesc)
                      .slice(0, 15)}
                    title="Ostatnio przeczytałem"
                  />
                </div>
                <div className="flex-column">
                  <BooksList
                    books={this.state.books
                      .filter(b => b.status === 'reading')
                      .sort(this.sortByDateReadDesc)
                      .slice(0, 5)}
                    title="Obecnie czytam"
                  />
                  <BooksList
                    books={this.state.books
                      .filter(b => b.status === 'to-read')
                      .sort(this.sortByDateReadDesc)
                      .slice(0, 10)}
                    title="Planuję przeczytać"
                  />
                </div>
              </div>
            </section>
          )}

          {Settings.showBooks3D && (
            <BooksList3D
              books={this.state.books}
              className={this.state.showMoreToggleOn ? '' : 'hidden'}
            />
          )}
        </StyledWrapper>
      </Layout>
    );
  }
}

export default ContactPage;
